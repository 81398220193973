@import '../color-variables.scss';
@import '../mixins.scss';

.main {
  background-color: $bg1;
  max-width: 1396px;
  margin: 0 auto;
  padding-top: 102px;
  margin-bottom: 22px;
}

.footer {
  width: 100vw;
  background-color: $card;
  align-items: center;
  padding: 15px 0;
  flex-direction: column;
  font-size: 14px;

  img {
    margin-bottom: 10px;
  }
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100vw;
  z-index: 999;
  background-color: $card;

  .content-header {
    max-width: 1440px;
    padding: 0 20px;
    height: 100%;
    position: relative;
    display: flex;
    min-height: 80px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    .header__title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
      color: $title;
      text-align: center;
    }
  }
}

.main {
  min-height: calc(100vh - 127px);
}
