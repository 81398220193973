@import './color-variables.scss';

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-Italic.ttf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/DMSans-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

iframe {
  display: none !important;
}

*::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
  background-color: #303757;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

.icon_close {
  &:hover {
    color: $title !important;
    transition: all 0.3s ease;
    transform: rotate(360deg);
  }
}

.apexcharts-legend-series {
  display: flex !important;
  margin-bottom: 5px !important;
  align-items: center;
}

.apexcharts-legend-text {
  color: $title !important;
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 200px !important;
}
