@import '../color-variables.scss';
@import '../mixins.scss';

.key-detail {
  &__name {
    font-size: 16px;
    color: #8d91a5;
  }

  &__info {
    font-size: 24px;
    justify-content: space-between;
  }

  &__price {
    font-weight: 700;
  }

  &__change {
    border-radius: 10px;
    height: 30px;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    font-size: 14px;

    &.green {
      background: rgba(40, 199, 111, 0.2);
      color: var(--Green, #28c76f);
    }

    &.red {
      background-color: rgba($red, 20%);
      color: $red;
    }
  }
}
