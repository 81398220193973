@import '../color-variables.scss';
@import '../mixins.scss';

.link {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: $link;
}

.box-about {
  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: $title;
    margin-bottom: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8d91a5;
  }

  &__content {
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: $title;
    margin: 20px 0 40px 0;

    @media only screen and (max-width: 768px) {
      margin: 10px 0 0 0;
    }
  }

  &__link-sui {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start !important;

      .logo-sui {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  &__bg2 {
    background: $bg2;
    span {
      color: $paragraph;
      text-transform: uppercase;
    }
  }
  &__chart-tooltip-text {
    background: $bg2;
    border-radius: 6px;
    font-size: 14px;
    padding: 5px 10px;
    color: $paragraph;
  }
  &__list-item {
    border-bottom: 1px solid $line-item;
    span {
      font-size: 16px;
      color: $paragraph;
    }
  }

  &__wrap-box-list {
    margin-left: -20px;
    width: calc(100% + 40px);
    margin-top: 24px;
  }
}

.box-top-count {
  &__title {
    font-size: 20px;
    font-weight: 700;
    align-items: center;
  }
  &__sub-title {
    color: #8d91a5;
    font-size: 18px;
  }
  .price {
    font-size: 26px;
    line-height: 1;
    font-weight: 700;
  }
  .box-count {
    font-size: 18px;
    font-weight: 500;
    border-radius: 14px;
    height: 30px;
    padding: 0 10px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &.red {
      background-color: rgba($red, 20%);
      color: $red;
    }
    &.green {
      background-color: rgba($green, 20%);
      color: $green;
    }
  }
}

.pd-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
  overflow-y: auto;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.box-accordion {
  &__item {
    margin-bottom: 15px;
    border-radius: 12px;
    border: 1px solid $border2;
    &__bg {
      border-radius: 12px;
      &.active {
        background: #1a203b;
      }
    }
    button {
      padding: 16px 15px;
      font-weight: 700;
      font-size: 16px;
    }
    button:focus {
      outline: none;
      box-shadow: none;
    }
    .text-title {
      font-size: 16px;
      .icon-token {
        width: 22px;
        height: 22px;
        object-fit: cover;
        border-radius: 50%;
      }
      span {
        color: $paragraph;
        display: inline-block;
      }
    }
  }
  &__panel {
    margin-top: -10px;
    span {
      font-size: 14px;
    }
    .label {
      color: $paragraph;
    }
  }
}

.recharts-wrapper {
  width: 100% !important;
  svg {
    width: 100% !important;
  }
}

#chart-container {
  width: 100%;
}

.box-table {
  overflow-y: auto;

  .icon-token {
    width: 22px;
    height: 22px;
    object-fit: cover;
    border-radius: 50%;
  }

  th {
    border: none;
  }

  tbody {
    tr:nth-child(2n) {
      background: $bg2;
    }
  }

  td {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 20px;
  }

  th {
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: $paragraph;
  }

  .address {
    a {
      &:hover {
        color: $link;
        text-decoration: underline;
      }
    }
  }

  .percent-holding {
    position: relative;
    padding-right: 20px;
  }

  .bar-progress {
    content: '';
    position: absolute;
    right: 0;
    background: rgba($green, 20%);
    float: right;
    height: 100%;
  }
}

.box-scroll {
  overflow-y: auto;
  padding-right: 20px;
}

.recharts-tooltip-wrapper {
  border: none;
  outline: none;
}

.recharts-default-tooltip {
  border: none;
  outline: none;
}

.dashboard_area_chart {
  &--label {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: $title;
  }
}

.dashboard_daily_active {
  &--label {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: $title;
  }
  &__chart-tooltip-text {
    background: $bg2;
    border-radius: 6px;
    font-size: 14px;
    padding: 5px 10px;
    color: $paragraph;
  }
  &--legend {
    color: $paragraph;
  }
}

.dashboard_sui_gar {
  &--label {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: $title;
  }
  &__chart-tooltip-text {
    background: $bg2;
    border-radius: 6px;
    font-size: 14px;
    padding: 5px 10px;
    color: $paragraph;
  }
  &--legend {
    color: $paragraph;
    margin-top: 30px !important;
  }
}

.treemap-chart-tooltip {
  font-size: 12px;
  color: $bg2;
  padding: 10px;
  font-weight: 700;
}

.key {
  justify-content: space-between;
  &__name {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__twitter-name {
    color: #8d91a5;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  &__price {
    font-size: 26px;
    font-weight: 700;
    margin-top: 4px;
  }

  &__change {
    border-radius: 14px;
    height: 30px;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: max-content;
    margin-top: 13px;

    svg {
      margin-right: 5px;
    }

    &.green {
      background: rgba(40, 199, 111, 0.2);
      color: var(--Green, #28c76f);
    }

    &.red {
      background-color: rgba($red, 20%);
      color: $red;
    }
  }

  &__label {
    color: var(--Text, #8d91a5);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }

  &__value {
    font-size: 24px;
    font-weight: 700;
  }
}

.button-next {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: -24px !important;
  width: 48px !important;
  height: 48px !important;
  background-image: url('../../assets/icons/next-btn.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 99;
}

.button-next {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: -24px !important;
  width: 48px !important;
  height: 48px !important;
  background-image: url('../../assets/icons/next-btn.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 99;
  cursor: pointer;

  @media only screen and (max-width: 1400px) {
    display: none;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;

    &:hover {
      background-image: url('../../assets/icons/next-btn.svg');
    }
  }

  &:hover {
    background-image: url('../../assets/icons/next-btn-hover.svg');
  }
}

.button-prev {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: -24px !important;
  width: 48px !important;
  height: 48px !important;
  background-image: url('../../assets/icons/pre-btn.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  z-index: 99;
  cursor: pointer;

  @media only screen and (max-width: 1400px) {
    display: none;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;

    &:hover {
      background-image: url('../../assets/icons/pre-btn.svg');
    }
  }

  &:hover {
    background-image: url('../../assets/icons/pre-btn-hover.svg');
  }
}
