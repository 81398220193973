@import '../color-variables.scss';
@import '../mixins.scss';

.app-select {
  position: relative;
  z-index: 104;

  &__badge {
    color: $paragraph;
    margin-left: 4px;
  }

  &__name {
    color: $paragraph;
    margin-left: 8px;
  }

  &__arrow-icon {
    color: $paragraph2;

    &:hover {
      color: $title;
    }
  }

  &__btn-select {
    @include text-tiny-regular;

    border: 1px solid $line;
    align-items: center;
    font-size: 14px;
    border-radius: 6px;
    z-index: 2;
    background-color: $card;
    justify-content: space-between;
    cursor: pointer;

    .icon {
      margin-right: 10px;
    }
  }

  &.small {
    .app-select__btn-select {
      height: 32px;
      padding: 0 14px;
    }
  }

  &.medium {
    .app-select__btn-select {
      height: 40px;
      padding: 0 14px;
    }
  }

  &.large {
    .app-select__btn-select {
      height: 48px;
      padding: 0 24px;
    }

    .app-select__menu {
      top: 30px;
    }
  }

  &__menu {
    position: absolute;
    background: $card1;
    border-radius: 0 0 8px 8px;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;
    top: 10px;
    z-index: -1;
    max-height: 200px;
    overflow-y: auto;
  }

  &__menu-item {
    @include text-tiny-regular;

    cursor: pointer;
    font-size: 14px;
    padding: 8px 18px;
    align-items: center;
    color: $paragraph;

    &:hover {
      background-color: rgba(18, 19, 24, 50%);
    }

    &.selected {
      background: $main;
      color: $title;
    }

    .icon {
      margin-right: 10px;
    }
  }

  &__precision.small {
    min-width: 60px;

    div.app-select__btn-select {
      padding: 0 8px;
      border: transparent;
      background-color: $card1;
    }
  }

  &__disabled {
    opacity: 0.6;
  }

  &__disabled &__btn-select {
    cursor: not-allowed;
  }
}
