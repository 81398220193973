@import '../color-variables';
@import '../mixins';

.app-pagination {
  list-style-type: none;
  display: flex;
  margin: 0 40px 20px;

  @include tablet {
    margin: 15px 20px 10px;
  }

  li {
    @include text-small-regular;

    border: 1px solid $line;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    color: $paragraph2;

    a {
      padding: 0 5px;
      min-width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.selected {
      color: $title;
    }

    &:hover {
      background: $main;
      color: $title;
    }

    &.disabled {
      color: $paragraph2;
      cursor: not-allowed;

      a {
        cursor: not-allowed;
      }
    }

    &.previous,
    &.next {
      background-color: transparent;
    }
  }
}
